<template>
  <div class="about">
    <h1>This is an about page</h1>
  </div>
</template>

<script>
import PublicLayout from '@/layouts/PublicLayout'
export default {
  name: 'About',
  created(){
this.$emit(`update:layout`, PublicLayout)
  }
}
</script>
